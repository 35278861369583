<template>
    <a-drawer
        class="drawer"
        :width="drawerWidth"
        destroyOnClose
        :afterVisibleChange="afterVisibleChange"
        :title="driwerTitle"
        :visible="visible"
        @close="close">
        <div class="drawer_body">
            <AccessGroupForm
                ref="accessGroupForm"
                :organization="organization" />
        </div>
        <div class="drawer_footer flex items-center">
            <a-button
                class="mr-2 w-full"
                type="primary"
                size="large"
                :loading="loading"
                @click="save">
                {{ $t('Save') }}
            </a-button>
            <a-button
                type="primary"
                ghost
                class="w-full"
                size="large"
                @click="close">
                {{ $t("Cancel") }}
            </a-button>
        </div>
    </a-drawer>
</template>


<script>
import AccessGroupForm from "./AccessGroupForm.vue";
export default {
    components: {
        AccessGroupForm
    },
    props: {
        organization: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            loading: false,
            visible: false,
        };
    },
    computed: {
        driwerTitle() {
            return this.title || this.$t("New access group");
        },
        drawerWidth() {
            const baseWidth = 720;
            const offset = 40;
            return this.windowWidth > baseWidth + offset
                ? baseWidth
                : this.windowWidth;
        },
        windowWidth() {
            return this.$store.state.windowWidth;
        },
        isMobile() {
            return this.$store.state.isMobile;
        },
    },
    methods: {
        afterVisibleChange(visible) {
            if (!visible) {
                this.zIndex = 1200;
            }
        },
        close() {
            this.visible = false;
        },
        open() {
            this.visible = true;
        },
        save() {
            this.loading = true;
            this.$refs.accessGroupForm.submit()
                .then((id) => {
                    this.close();
                    this.$emit('update', id)
                })
                .finally(() => {
                    this.loading = false;
                })

        }
    },
};
</script>

<style lang="scss" scoped>
.drawer_body {
  display: flex;
  flex-direction: column;
  padding: 40px;
  min-height: calc(100% - 80px);
}

.drawer::v-deep {
  .ant-drawer-body {
    padding: 0px;
    height: calc(100% - 40px);
  }

  .drawer_header {
    border-bottom: 1px solid var(--borderColor);
  }

  .drawer_footer {
    border-top: 1px solid var(--borderColor);
    height: 80px;
    background: var(--bgColor);
    padding: 0 15px;
    padding-top: 10px;
    padding-bottom: 30px;
    align-items: center;
  }

  .drawer_body {
    .drawer_scroll {
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }

}
</style>
